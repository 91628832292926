<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:课程资源库
 -->
<template>
  <div>
    <article v-show="!showDetail" class="resume-list">
      <section class="search-expectCity">
        <div class="keyWords-expectCity">
          <el-input v-model="searchForm.keyWords" class="input-keyWords" placeholder="请输入关键字搜索" @keyup.enter.native="handleSearch" />
          <el-button type="primary" class="btn-search" @click="handleSearch">搜索</el-button>
        </div>
        <section style="display: flex;justify-content: space-between">
          <dl class="search-item-container">
            <dt class="search-item-container-title">课程类别：</dt>
            <dd class="search-item-container-list" :class="[showMore?'search-container-list-open':'search-container-list-close']">
              <a v-for="item in courseTypeList" :key="item.dictCode" class="search-item-name" :class="[{'search-item-name-focus':selectForm.cuNature===item.cuName}]" @click="handleSelectType(item)">
                {{ item.cuName }}
              </a>
            </dd>
          </dl>
          <div class="more-container" @click="toggleShowMore">
            更多
            <img style="vertical-align: middle;margin-left: 10px" src="../../../../assets/images/arrow-down.png" :class="[showMore?'arrow-open':'arrow-close','arrow-normal']">
          </div>
        </section>

        <float-label ref="floatLabel" class="float-label" :label-map="floatLabelMap" @handlerRemove="handleRemoveLabel"></float-label>
      </section>
      <course-list :records="records" :total="total" :current-page="currentPage" @openCourse="handleOpenCourse" @changePage="handleChangePage"></course-list>
    </article>
    <detail v-if="showDetail" :row-data="itemCourse" @close="showDetail=false"></detail>
  </div>
</template>

<script>
import courseList from './components/courseList'
import listMixin from '../../../../utils/mixins/listMixin'
import detail from './detail'
export default {
  components: {
    courseList,
    detail
  },
  mixins: [listMixin],
  data() {
    return {
      showMore: false,
      selectForm: {

      },
      // 浮动标签列表
      floatLabelMap: {},
      records: [],
      // 课程类别
      courseTypeList: [],
      showDetail: false,
      itemCourse: null
    }
  },
  mounted() {
    document.getElementById('app').scrollTop = 0
  },
  activated() {
    this.getCourseTypeList()
    document.getElementById('app').scrollTop = 0
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore
    },
    // 获取课程列表
    getCourseTypeList() {
      this.$get(this.urls.atcuList, { atType: 1 }).then(result => {
        if (result.code === 'SUCCESS') {
          this.courseTypeList = result.data
          this.requestData()
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
      })
    },
    handleChangePage(page) {
      this.currentPage = page
      this.requestData()
    },
    requestData() {
      this.$get(this.urls.courseList, this.searchForm, [this.currentPage, 12]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.records = data.records
          this.total = data.total
        } else {
          this.$message.error(result.msg)
        }
      }).catch(err => {
        console.log('err==', err)
      })
    },
    // 移除选择的标签
    handleRemoveLabel(item) {
      this.searchForm[item.key] = null
      this.selectForm[item.key] = null
      this.currentPage = 1
      this.requestData()
    },
    // 选择课程类别
    handleSelectType(item) {
      this.searchForm.cuNature = item.atCode
      this.selectForm.cuNature = item.cuName
      this.handlePitchOn(item.cuName, 'cuNature')
    },
    // 选中搜索条件,并将条件展示到浮动标签
    handlePitchOn(value, key) {
      this.$set(this.floatLabelMap, key, { value, key })
      this.currentPage = 1
      this.requestData()
    },
    handleOpenCourse(item) {
      this.itemCourse = item
      this.showDetail = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/list-search";
</style>
